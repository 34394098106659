<template>
	<article-wrapper
			:progressColor="`#0096D6`">

			<template v-slot:hero>
				<hero class="csr__hero">
					<figure>Corporate social responsibility</figure>
					<h1>Sustainability <br>at dtcc</h1>
					<p>Environmental sustainability is embedded into the DTCC culture, but in 2020 we brought greater focus to our efforts by aligning our activities to the Sustainable Development Goals (SDGs) identified by the United Nations. </p>
				</hero>
			</template>

			<template v-slot:body>
				<div class="csr__body">
					<div class="body-columns--small">
						<p>While the global pandemic has created many challenges, it has also given us key insights into our operations and ways of working differently to support our sustainability strategy. We are using these learnings to develop best practices that will shape our future plans to reduce our carbon footprint. </p>
					</div>
				</div>

				<div class="body-quote">
					<div class="body-quote__before"></div>
					<div class="body-quote__content body-quote__content--right">
						<div class="body-quote__quote">
							"We have an opportunity and an obligation to manage our  organization  in ways that are socially responsible and environmentally sustainable. It’s not just the right thing to do, but it’s a powerful way to engage, attract and retain employees from across the globe. Together, we can demonstrate our shared willingness to take ownership of sustainability efforts and contribute to the future well-being of the company, society and our planet."
							<div class="body-quote__credit">— <strong>Fikir Sanders,</strong> Director, Corporate Social Responsibility</div>
						</div>
					</div>
					<div class="body-quote__after"></div>
				</div>

				<div class="csr__body">

					<div class="video-container">
						<iframe src="https://player.vimeo.com/video/531316702" width="1172" height="659" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
					</div>

					<p><strong>Susan Cosgrove,</strong> CFO and Executive Sponsor</p>

				</div>

				<div class="impact">
					<div class="csr__body">

						<div class="impact__heading">
							<h2>Working to reduce <br> <span>our impact</span></h2>

							<div>
								<p>To obtain a picture of DTCC’s energy usage, our facilities team started monitoring our business centers in 2016.  We reduced overall energy consumption by XX% over a four-year period ending in 2020. Steps like switching to efficient LED lighting and improving ventilation systems helped save a total of 4.5 million kilowatt hours, or about the same amount of energy that 367 homes would use in a year.</p>
							</div>
						</div>

						<picture>
							<source media="(max-width: 599px)" srcset="~@/assets/csr/sustainabilityChartMobile@2x.png">
							<source media="(min-width: 600px)" srcset="~@/assets/csr/sustainabilityChart@2x.png">
							<img src="~@/assets/csr/sustainabilityChart@2x.png" width="100%">
						</picture>

					</div>
				</div>

				<div class="csr__body">
					<div class="image-list">
						<div class="image-list__item image-list__item--reverse">
							<div>
								<figure>Initiatives</figure>
								<h2>Engaging employees <br> <span>and their families</span></h2>
								<p>Our DTCC Tampa office was honored with the first-ever Helping Hands Award by Feeding Tampa Bay in support of local COVID-19 relief efforts. Our volunteer and financial contributions aided the non-profit in serving 10,000 families a week hearty and healthy meals during the pandemic.</p>
							</div>
							<img src="~@/assets/csr/sustainabilityImg1@2x.jpg" width="548" />
						</div>

						<div class="image-list__item">
							<div>
								<h2>Earth Day</h2>
								<p>DTCC held a digital Earth Day celebration in April centered around an employee art contest with a sustainability theme. Entries were displayed using our internal digital communication platforms to promote awareness.  The winners won donations on their behalf to Nature Conservation Foundation and Surfrider Foundation.</p>
							</div>
							<img src="~@/assets/csr/sustainabilityImg2@2x.jpg" width="548" />
						</div>

						<div class="image-list__item image-list__item--reverse">
							<div>
								<h2>Behind the book</h2>
								<p>We hosted a virtual book discussion in partnership with Behind the Book, a literacy nonprofit in New York City. The event featured the children’s book, “The Water Princess,” by author, Susan Verde. The book’s theme of water conservation is directly connected to the DTCC facility in Chennai, India, which regularly experiences water shortages. To bring together employees and their children, the event encouraged participants to submit artwork related to water conservation, with all the projects compiled into a book titled “The Great Water Tanks for DTCC,” which was distributed to each child.</p>
							</div>
							<img src="~@/assets/csr/sustainabilityImg3@2x.jpg" width="548" />
						</div>

					</div>
				</div>
			</template>

			<template v-slot:footer>
				<article-next class="csr__next" :image="'csr/bgTalent@2x.jpg'" :link="{name: 'csr', params: {
					article: 'talent'}}" target-id="next_article" v-hero-click="`#next_article`">
					<template v-slot:label>Diversity & Inclusion</template>
					<template v-slot:title>Attracting & Advancing Diverse Talent </template>
				</article-next>
			</template>


		</article-wrapper>
</template>

<script>
import ArticleNext from '@/components/ArticleNext'
import ArticleWrapper from '@/components/ArticleWrapper'
import Hero from '@/components/Hero'
import Boxes from '@/components/Boxes'

export default {
	name: 'Sustainability',
	components: {
		ArticleNext,
		ArticleWrapper,
		Hero,
		Boxes
	},
}
</script>

<style scoped lang="scss">

.csr {

	&__hero {
		background-image: url('~@/assets/csr/bgSustainability@2x.jpg');
	}

	.body-quote {
		background-image: url('~@/assets/csr/bgSustainabilityQuote@2x.jpg');
	}

	.impact {
		background: color_('background', 'light');

		&__heading {
			//display: flex;
			margin-bottom: 20px;

			@include media-query-min-width('tablet') {
				display: flex;
			}

			h2 {
				margin-top: 0;
			}

			* {
				flex: 1;
			}

		}
	}

}

</style>
